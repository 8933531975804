<template>
  <n-layout
    :content-style="this.isMobile ? 'padding: 8px; max-height: 90vh' : 'padding: 64px; max-height: 93vh'"
    :native-scrollbar="false"
  >
    <n-divider>Vartotojai</n-divider>

    <n-space justify="space-around" size="large">
      <n-card title="Naujų vartotojų registracija" style="text-align: center;">
        <div v-if="firstChartOptions.loading">
          <n-spin size="large" />
        </div>
        <div v-else>
          <n-statistic label="Naujų vartotojų šiandien:" :value="firstChartData.datasets[0].data.slice(-1)[0]">
            <template #prefix><n-icon><PeopleOutline /></n-icon></template>
          </n-statistic><br>
          <BarChart :chartData="firstChartData" :options="firstChartOptions" class="chart"/>
        </div>
      </n-card>

      <n-card title="Vartotojų iš viso" style="text-align: center;">
        <div v-if="secondChartOptions.loading">
          <n-spin size="large" />
        </div>
        <div v-else>
          <n-statistic label="Vartotojų iš viso:" :value="secondChartData.datasets[0].data.slice(-1)[0]">
            <template #prefix><n-icon><PeopleOutline /></n-icon></template>
          </n-statistic><br>
          <LineChart :chartData="secondChartData" :options="secondChartOptions" class="chart"/>
        </div>
      </n-card>

      <n-card title="Vartotojai turintys administratoriaus teises" style="text-align: center;">
        <div v-if="thirdChartOptions.loading">
          <n-spin size="large" />
        </div>
        <div v-else>
          <n-statistic label="Administratorių iš viso:" :value="thirdChartData.datasets[0].data.slice(-1)[0]">
            <template #prefix><n-icon><PeopleOutline /></n-icon></template>
          </n-statistic><br>
          <LineChart :chartData="thirdChartData" :options="thirdChartOptions" class="chart"/>
        </div>
      </n-card>

    </n-space>
  </n-layout>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';
import { useIsMobile } from '@/utils/composables'

import { BarChart, LineChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";

import jwtInterceptor from '../../shared/jwt.interceptor'
import config from '../../shared/config'

Chart.register(...registerables);

import { PeopleOutline, } from '@vicons/ionicons5'

const isMobileRef = useIsMobile()


const refactorToChartData = (data) => {

  var startDate = data[data.length - 1]._id
  var endDate = (new Date()).toISOString().slice(0, 10).replace(/-/g, "-").replace("T", " ")  // data[0]._id

  var startDate_ = new Date(startDate)
  var endDate_ = new Date(endDate)
  // console.log(startDate, endDate)

  var dateArray = []
  var dateArrayLength = parseInt((endDate_ - startDate_) / (1000 * 60 * 60 * 24), 10);
  for (let i = 0; i <= dateArrayLength; i++) {
    let date_ = new Date(new Date(startDate_).setDate(startDate_.getDate() + i))
    dateArray.push(date_.toISOString().split('T')[0])
  }
  // console.log(dateArrayLength, dateArray)

  var dataArray = []
  let dateArrayFromDB = data.map(a => a._id);
  dateArray.forEach(item => {
    if (dateArrayFromDB.includes(item)) {
      dataArray.push(data.pop().count)
    } else {
      dataArray.push(0)
    }
  })

  return { dateArray, dataArray }

}

const findCumulativeSum = (arr) => {
   const creds = arr.reduce((acc, val) => {
      let { sum, res } = acc;
      sum += val;
      res.push(sum);
      return { sum, res };
   }, {
      sum: 0,
      res: []
   });
   return creds.res;
};

export default defineComponent({
  name: 'server_stats',
  components: {
    PeopleOutline,

    BarChart,
    LineChart,
  },
  setup() {

    onMounted(async () => {

      // get first / scond chart
      jwtInterceptor.get(config.backend_server + "statistics/getNewUsers", {}).then(response => {
        if (response && response.status === 200) {
          let { dateArray, dataArray } = refactorToChartData(response.data)
          // console.log(dateArray, dataArray)
          firstChartDataRef.value.labels = dateArray
          firstChartDataRef.value.datasets[0].data = dataArray
          firstChartOptionsRef.value.loading = false

          secondChartDataRef.value.labels = dateArray
          secondChartDataRef.value.datasets[0].data = findCumulativeSum(dataArray)

        } else {
          console.log('statsMain/getNewUsers: something did not work')
        }
      })
      // get second chart
      jwtInterceptor.get(config.backend_server + "statistics/getActiveUsers", {}).then(response => {
        if (response && response.status === 200) {
          let { dataArray } = refactorToChartData(response.data)
          // console.log(dateArray)
          secondChartDataRef.value.datasets.push({
            label: 'Aktyvūs vartotojai',
            data: findCumulativeSum(dataArray),
            backgroundColor: ['#fdb913'],
          })
          secondChartOptionsRef.value.loading = false

        } else {
          console.log('statsMain/getActiveUsers: something did not work')
        }
      })
      // get third chart
      jwtInterceptor.get(config.backend_server + "statistics/getAdmins", {}).then(response => {
        if (response && response.status === 200) {
          let { dateArray, dataArray } = refactorToChartData(response.data)
          // console.log(dateArray, dataArray)
          thirdChartDataRef.value.labels = dateArray
          thirdChartDataRef.value.datasets[0].data = findCumulativeSum(dataArray)
          thirdChartOptionsRef.value.loading = false
        } else {
          console.log('statsMain/getAdmins: something did not work')
        }
      })

    })

    // chart data and options objects

    // first chart
    const firstChartOptionsRef = ref({
      loading: true,
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
      },
    })

    const firstChartDataRef = ref({
      labels: [],
      datasets: [
        {
          label: 'Vartotojai',
          data: [],
          backgroundColor: ['#006a44'],
        },
      ],
    })

    // second chart
    const secondChartOptionsRef = ref({
      loading: true,
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
      },
    })

    const secondChartDataRef = ref({
      labels: [],
      datasets: [
        {
          label: 'Vartotojai',
          data: [],
          backgroundColor: ['#006a44'],
        },
      ],
    })

    // third chart
    const thirdChartOptionsRef = ref({
      loading: true,
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
      },
    })

    const thirdChartDataRef = ref({
      labels: [],
      datasets: [
        {
          label: 'Administratoriai',
          data: [],
          backgroundColor: ['#006a44'],
        },
      ],
    })

    return {
      isMobile: isMobileRef,
      firstChartData: firstChartDataRef.value,
      firstChartOptions: firstChartOptionsRef.value,
      secondChartData: secondChartDataRef.value,
      secondChartOptions: secondChartOptionsRef.value,
      thirdChartData: thirdChartDataRef.value,
      thirdChartOptions: thirdChartOptionsRef.value,
    }
  },
  methods: {
  }
})
</script>

<style scoped>

.chart {
  min-width: 200px;
  min-height: 200px;
}
</style>
